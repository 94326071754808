@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
  --accent: #ff6a88;
  --font-shade: rgba(0, 0, 0, 0.5);
  /* e7ffff */
  --color-1: #afd5f0;
  /* 77dd77 ,c9f1c9*/
  --color-2: #c9f1c9;
  /* def376, */
  --color-3: #def376;
  /* d4fafa */
  --color-4: #d4fafa;
  --color-about: #e7e4f1;
  --font-family: "Raleway", sans-serif;
  --balck-shade: #1a1915;
}
* {
  margin: 0;
  padding: 0;
}
.container-fluid {
  padding-left: 0;
  padding-right: 0;
}
.nav_black {
  background-color: var(--balck-shade);
}
.navbar-toggler {
  outline: none;
}
.nav-link {
  color: #fff;
  font-weight: bold;
}
.nav-link:hover {
  color: var(--accent);
}
.nav-link.active {
  color: var(--accent);
}
.navbar-toggler {
  color: #fff;
}

.Logo {
  justify-content: center;
  align-items: center;
}
.logo-text {
  font-family: "Dancing Script", cursive;
  font-weight: bold;
  color: #fff;
  font-size: 2.2rem;
  text-decoration: none;
}
.logo-text:hover {
  color: #fff;
}
.width-custom {
  width: 33.33%;
}
.booking-button {
  outline: none;
  border: 2px solid var(--accent);
  border-radius: 0.5rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  margin-right: 1rem;
  color: #fff;
  background-color: transparent;
}
.booking-container {
  justify-content: flex-end;
  align-items: center;
}

.custom-header {
  background-color: var(--balck-shade);
  background-image: url("https://res.cloudinary.com/purnesh/image/upload/v1658301118/mainpage_2-min_w0yvfg.jpg");
  height: 100vh;
  width: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

.header_title_container {
  justify-content: center;
  align-items: center;
  height: 100%;
}
.header_title {
  width: 40%;
  text-align: center;
  color: #fff;
}
.main_content_section {
  min-height: 80vh;
}
.main_content_sub_section_g {
  display: flex;
  justify-content: center;
  min-height: 80vh;
}
.color_section_1 {
  padding: 1rem;
  background-color: var(--color-1);
}
.color_section_2 {
  padding: 1rem;
  background-color: var(--color-2);
}
.color_section_3 {
  padding: 1rem;
  background-color: var(--color-3);
}
.color_section_4 {
  padding: 1rem;
  background-color: var(--color-4);
}
.color_section_5 {
  padding: 1rem;
}
.left,
.right {
  width: 50%;
}
.main_content_sub_section_heading {
  min-height: 80vh;
}
.main_content_sub_section_heading .left {
  display: flex;
  justify-content: center;
  align-items: center;
}

.main_content_sub_section_heading .left h1 {
  font-size: 3rem;
  font-family: var(--font-family);
}
.main_content_sub_section_heading .right {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main_content_sub_section_heading .right .right_image_1,
.main_content_sub_section_heading .right .right_image_2 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  overflow: hidden;
}
.main_content_sub_section_heading .right .right_image_1 img,
.main_content_sub_section_heading .right .right_image_2 img {
  max-width: 90%;
}
.main_content_sub_section_heading .right img {
  max-width: 80%;
  max-height: 75vh;
}
.main_content_sub_section_1 {
  min-height: 80vh;
}
.main_content_sub_section_1 .right {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.main_content_sub_section_1 .right h1 {
  font-size: 4rem;
  font-family: var(--font-family);
}
.main_content_sub_section_1 .right p {
  font-size: 2rem;
  font-family: var(--font-family);
  color: var(--font-shade);
}
.main_content_sub_section_1 .right .link {
  text-decoration: none;
}

.main_content_sub_section_1 .left {
  flex-direction: column;
  display: flex;
  align-items: center;
}
.main_content_sub_section_1 .left .image_1 {
  width: 100%;
  display: flex;
  padding: 1rem;
}
.main_content_sub_section_1 .left .image_1 {
  justify-content: center;
  align-items: center;
  height: 100%;
}
.main_content_sub_section_1 .left .image_1 img {
  max-width: 100%;
  max-height: 70vh;
}

/* 2 */
.main_content_sub_section_2 {
  min-height: 80vh;
}
.main_content_sub_section_2 .right {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.main_content_sub_section_2 .right h1 {
  font-size: 4rem;
  font-family: var(--font-family);
}
.main_content_sub_section_2 .right p {
  font-size: 2rem;
  font-family: var(--font-family);
  color: var(--font-shade);
}
.main_content_sub_section_2 .right .link {
  text-decoration: none;
}
.main_content_sub_section_2 .left .image_1,
.main_content_sub_section_2 .left .image_2 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 1rem;
}
.main_content_sub_section_2 .left .image_1 {
  justify-content: center;
  align-items: center;
}
.main_content_sub_section_2 .left .image_1 img {
  max-width: 80%;
  max-height: 65vh;
}
.main_content_sub_section_2 .left .image_2 img {
  width: 80%;
}

/* 2 */
.main_content_sub_section_3 {
  min-height: 80vh;
}

.main_content_sub_section_3 .right {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.main_content_sub_section_3 .right h1 {
  font-size: 4rem;
  font-family: var(--font-family);
}
.main_content_sub_section_3 .right p {
  font-size: 2rem;
  font-family: var(--font-family);
  color: var(--font-shade);
}
.main_content_sub_section_3 .right .link {
  text-decoration: none;
}
.main_content_sub_section_3 .left .image_1,
.main_content_sub_section_3 .left .image_2 {
  display: flex;
  padding: 1rem;
}
.main_content_sub_section_3 .left .image_2 {
  justify-content: flex-end;
}
.main_content_sub_section_3 .left .image_1 img {
  width: 80%;
}
.main_content_sub_section_3 .left .image_2 img {
  max-width: 80%;
  max-height: 50vh;
}
.footer_h1 {
  width: 60%;
  font-size: 1.5rem !important;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 10vh;
  width: 100%;
  background-color: var(--balck-shade);
}
.footer_left {
  width: 49%;
  display: flex;
  align-items: center;
  padding: 1rem;
}
.footer_left a {
  margin-right: 1rem;
  font-size: 1.2rem;
  color: #fff;
  transition: all 0.2s ease-in-out;
}
.footer_right {
  display: flex;
  width: 49%;
  align-items: center;
  padding: 1rem;
  justify-content: flex-end;
}

.footer_right a {
  text-decoration: none;
  color: #fff;
  transition: all 0.2s ease-in-out;
}

.footer_left a:hover,
.footer_right a:hover {
  color: var(--accent);
  transition: all 0.2s ease-in-out;
}
.appointment_section {
  display: flex;
  justify-content: center;
  padding: 1rem;
}
.appointment_form {
  min-height: 78vh;
  width: 70%;
}
.about_us_section {
  background-color: var(--color-about);
  display: flex;
  padding: 1rem;
  min-height: 82vh;
  justify-content: center;
  align-items: center;
}
.about_us_content {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.about_us_content_images img {
  max-width: 100%;
  max-height: 70vh;
}
.about_us_content_text {
  padding: 1rem;
}
